@import '@styles/variables';

.component {
  position: relative;
  height: 40px;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    display: none;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.countrySelectContainer {
  position: absolute;
  left: 0;
  z-index: 10;
  width: 488px;
  height: 560px;
  background-color: #fff;
  box-shadow: 0 4px 30px rgba(108, 72, 0, 0.2);
}
