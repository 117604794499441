.component {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.iconBox {
  margin-right: 4px;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: #fff;
  border-radius: 2px;
}

.iconBox:last-child {
  margin-right: 0;
}
