@import '@styles/variables';

.component {
  position: absolute;
  top: -5px;
  right: calc(100% - 11px);
  z-index: 2;
  padding: 0 5px;
  display: flex;
  align-items: center;
  max-width: 27px;
  height: 15px;
  background: var(--color-alarm);
  border-radius: 5px;
  font-size: 10px;
  font-family: var(--font-graphik);
  line-height: 15px;
  color: #ffffff;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    top: 3px;
    right: calc(100% - 26px);
  }

  @media screen and (max-width: $tablet-window-width - 1px) {
    right: calc(100% - 22px);
  }
}
