@import '@styles/variables';

.input {
  width: 100%;
  height: 48px;

  :global(.e-inputField) {
    padding: 10px 38px 10px 16px;
    height: 100%;
    background-color: #ffffff;
    border-color: #ffffff !important;
    border-radius: 4px 0 0 4px;
  }
}

.placeholder {
  font-size: 14px;
  line-height: 20px;
  color: #a0a0a0;
}

.clearButton {
  position: absolute;
  right: 70px;
}

.searchButton {
  margin-right: 4px;
  padding-right: 14px;
  padding-left: 14px;
  width: 52px;
  height: 48px;
  border-radius: 0 4px 4px 0;
}
