.component {
  display: flex;
}

.value {
  white-space: nowrap;
  font-feature-settings: 'tnum' on, 'lnum' on;
}

.rest {
  font-variant-position: super;
  font-feature-settings: 'sups';
}
