@import '@styles/variables';
@import '@styles/mixins';

.component {
  @include boxWithShadow();

  padding: 16px 16px 24px;
  display: inline-flex;
  flex-direction: column;
  max-width: calc(100% - 32px);
}

.heading {
  font-size: 24px;
  font-weight: bold;
}

.buttonsContainer {
  padding-top: 24px;
  display: flex;
  align-items: center;

  @media screen and (max-width: $big-mobile-window-width - 1px) {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
}

.buttonChangeCountry {
  margin-left: 8px;

  @media screen and (max-width: $big-mobile-window-width - 1px) {
    margin-top: 16px;
    margin-left: 0;
  }
}
