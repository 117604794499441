@import '@styles/variables';

.component {
  margin: 0 auto;
  padding-right: 32px;
  padding-left: 32px;
  width: 100%;
  max-width: $layout-content-max-width;
  transition: padding 0.33s;

  @media (max-width: $tablet-window-width - 1px) {
    padding-right: 16px;
    padding-left: 16px;
  }
}
