@import '@styles/variables';

.component {
  position: relative;
  margin-right: -16px;
  margin-left: -16px;
  display: flex;
  width: 100vw;
  min-width: 320px;
  background-color: var(--color-big);
}

.swipeContainer {
  position: relative;
  left: -100%;
  display: inline-flex;
  align-items: center;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  transition: left 0.33s ease-in-out;
  will-change: left;
  backface-visibility: hidden;

  &.isSearchShow {
    left: 0;
  }
}

.slide {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}

.buttonBack {
  padding-right: 12px;
  padding-left: 16px;
  height: 48px;
}
