/** breakpoints */
$desktop-window-width: 1472px;
$small-desktop-window-width: 1184px;
$big-tablet-window-width: 992px;
$tablet-window-width: 736px;
$big-mobile-window-width: 558px;
$mobile-window-width: 320px;

/** Максимальная ширина рабочей области контента */
$layout-content-max-width: 1376px;
