.component {
  display: flex;
  flex-direction: column;

  li {
    padding-bottom: 8px;
  }

  a {
    font-size: 14px;
    line-height: 14px;
  }
}
