.component {
  display: flex;
}

.bold {
  font-weight: 700;
}

.leftSymbol {
  margin-right: 4px;
}

.rightSymbol {
  margin-left: 5px;
}
