.component {
  position: relative;
  font-size: 14px;
  font-family: var(--font-graphik);
  line-height: 20px;
}

.arrowIcon {
  margin-left: 8px;
}
