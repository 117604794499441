.component {
  display: flex;
  align-items: center;

  > li {
    margin-left: 30px;
  }

  > li:first-child {
    margin-left: 0;
  }
}

.link {
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 14px;
}

.linkIcon {
  margin-right: 3px;
}
