@import '@styles/variables';

/* Containers */
.component {
  position: relative;
  z-index: 1001;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  height: var(--header-height);
  background-color: var(--color-big);
}

.content {
  position: relative;
}

/* Confirmation country */
.countryConfirmation {
  position: absolute;
  top: 40px;
  z-index: 10;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    top: 50px;
  }
}

/* Bottom header */
.bottomHeaderContainer {
  position: static;
  left: auto;
  width: 100%;
  height: var(--sticky-header-height);

  /* Line */
  &::after {
    position: fixed;
    top: 0;
    left: 50%;
    z-index: -1;
    display: none;
    width: 100%;
    min-width: 100%;
    height: var(--sticky-header-height);
    background-color: var(--color-big);
    transform: translateX(-50%);
    transition: box-shadow 0.33s linear;
    content: '';
  }

  /* Line in fly */
  &.isFlyHeader::after {
    display: block;
  }

  /* Line with shadow */
  &.isShowShadow::after {
    box-shadow: 0 4px 30px rgba(108, 72, 0, 0.2);
  }

  &.isFlyHeader {
    position: fixed;
    top: 0;
    width: calc(100% - 64px);
    max-width: calc($layout-content-max-width - 64px);
  }

  @media screen and (max-width: $tablet-window-width - 1px) {
    &,
    &.isFlyHeader {
      width: calc(100% - 32px);
      max-width: calc($layout-content-max-width - 32px);
    }

    &::after,
    &.isFlyHeader::after {
      display: none;
    }
  }
}
