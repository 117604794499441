@import '@styles/variables';

.component {
  display: flex;
  flex-direction: column;
}

.imageContainer {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: hidden;

  @media screen and (max-width: $big-mobile-window-width - 1px) {
    width: 100%;
  }
}

.imageContainer img {
  filter: brightness(0.95);
}

.textNoPhoto {
  margin-top: 18px;
  padding-left: 6px;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--color-grey);
}

.price {
  padding-top: 16px;
  color: #000;
}

.description {
  padding-top: 4px;
  font-size: 14px;
  line-height: 20px;
}
