@import '@styles/variables';

.component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
}

/* Left section */
.slideLeftSection {
  display: flex;
  align-items: center;
}

.menuButton {
  padding-right: 12px;
  padding-left: 16px;
  height: 40px;
  background-color: transparent !important;
  border-color: transparent !important;
}

.logoLink {
  padding-left: 4px;
  height: 37px;
}

.logoText {
  margin-left: 11px;
  width: 115px;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000;
}

/* Right section */
.slideRightSection {
  display: flex;
  align-items: center;
}

.searchButton {
  margin-right: 5px;
  padding-right: 7px;
  padding-left: 12px;
  height: 40px;

  &:hover {
    color: inherit;
  }
}

.searchIcon {
  display: block;
  fill: #000000 !important;
}

.cart {
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 17px;
  line-height: 24px;
}
