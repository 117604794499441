@import '@styles/variables';

.component {
  margin-top: 16px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    flex-direction: column;
  }
}

.productList {
  margin-right: 32px;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    margin-top: 32px;
    margin-right: 0;
  }

  @media screen and (max-width: $big-mobile-window-width - 1px) {
    margin-top: 28px;
    width: calc(100% + 8px);
  }
}
