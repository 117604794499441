@import '@styles/variables';

.component {
  display: flex;
  width: 100%;
}

/* Search Input */
.searchInput {
  width: 100%;
  height: 56px;

  :global(.e-inputField) {
    padding-right: 38px;
    background-color: #ffffff;
    border-color: #ffffff !important;
    border-right: none;
    border-radius: 4px 0 0 4px;
  }

  &.isFlyHeader {
    :global(.e-inputField) {
      border-radius: 0;

      @media screen and (max-width: $big-tablet-window-width - 1px) {
        border-radius: 4px 0 0 4px;
      }
    }
  }

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    height: 40px;

    :global(.e-inputField) {
      padding: 10px 16px;
      height: 100%;
      background-color: #ffffff;
      border-color: #ffffff;
      border-right: none;
      border-radius: 4px 0 0 4px;
    }

    :global(.e-inputPlaceholder) {
      right: 16px;
      left: 16px;
      color: #a0a0a0;
    }
  }
}

/* Search Input Placeholder */
.placeholder {
  display: flex;
  align-items: center;
}

.placeholderText {
  font-size: 14px;
  line-height: 20px;
  color: #a0a0a0;
}

.demoValueButton {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #a0a0a0;
  cursor: pointer;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    content: '';
  }

  &:hover {
    color: #a0a0a0;
  }
}

/* Button "Clear" */
.clearButton {
  position: absolute;
  top: 0;
  left: -32px;
  display: flex;
  align-items: center;
  height: 100%;
  color: #000000;

  &:hover {
    color: var(--color-red);
  }
}

/* Button "Search" */
.searchButton {
  position: relative;
  flex-shrink: 0;
  width: 128px;
  border-radius: 0 4px 4px 0;
  font-weight: bold;
  white-space: nowrap;

  &.isFlyHeader {
    border-radius: 0;

    @media screen and (max-width: $big-tablet-window-width - 1px) {
      border-radius: 0 4px 4px 0;
    }
  }

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    padding-right: 14px;
    padding-left: 14px;
    flex-shrink: 0;
    width: 52px;
    height: 40px;
    border-radius: 0 4px 4px 0;
    font-size: 0;
  }
}

.searchIcon {
  display: none;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    display: block;
    width: 24px;
    height: 22px;
    fill: #ffffff;
  }
}
