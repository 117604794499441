.component {
  display: flex;
}

.listBlock {
  padding-right: 32px;
  width: 160px;
}

.listHeading {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #000000;
}
