@import '@styles/variables';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.headerAndContent {
  display: flex;
  flex-direction: column;
}

.content {
  padding-top: 40px;
  padding-bottom: 122px;

  @media screen and (max-width: $desktop-window-width - 1px) {
    padding-bottom: 80px;
  }

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @media screen and (max-width: $tablet-window-width - 1px) {
    padding-top: 24px;
  }
}
