.component {
  position: relative;
}

.link {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;

  &,
  &:visited {
    text-decoration: none;
    color: #000;
  }

  &:hover {
    text-decoration: none;
    color: var(--color-red);
  }
}

.label {
  margin-left: 15px;
}
