@import '@styles/variables';

.component {
  position: relative;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  font-weight: inherit;
  font-family: inherit;
  line-height: inherit;
  cursor: pointer;

  &:after {
    margin-top: 8px;
    display: block;
    height: 10px;
    background-color: #ffffff;
    content: '';

    @media screen and (max-width: $big-tablet-window-width - 1px) {
      margin-top: 0;
      height: 4px;
    }
  }

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    margin-right: 32px;
  }
}

.icon {
  position: absolute;
  top: 50%;
  right: -64px;
  transform: translateY(-50%);

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    right: -32px;
    margin-top: 2px;
    width: 22px;
  }
}
