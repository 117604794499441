@import '@styles/variables';

.component {
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  grid-row-gap: 38px;
  grid-column-gap: 32px;
  width: 100%;
  max-width: 768px;
  overflow: hidden;

  @media screen and (max-width: $big-mobile-window-width - 1px) {
    grid-template-columns: repeat(auto-fill, calc(50% - 8px));
    grid-column-gap: 8px;
  }
}
