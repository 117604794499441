@import '@styles/mixins';

.component {
  position: relative;
}

/* User */
.userButton {
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 14px;
}

.userIcon {
  margin-right: 3px;
}

/* Drop menu */
.menu {
  @include boxWithShadow();

  position: absolute;
  top: 42px;
  right: 0;
  z-index: 20;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  min-width: 165px;
}

.menuButton {
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  height: 40px;
}

.menuItemIcon {
  margin-right: 8px;
}
