@import '@styles/variables';

.component {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media screen and (max-width: $tablet-window-width - 1px) {
    display: none;
  }
}

/* Logo */
.logo {
  padding-top: 1px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    flex-direction: row;
    align-items: center;
  }
}

.logoLink {
  flex-shrink: 0;
  width: 139px;
  height: 26px;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    margin-left: 40px;
    width: 48px;
    height: 37px;
  }
}

.logoIcon {
  @media screen and (max-width: $big-tablet-window-width - 1px) {
    display: none;
  }
}

.logoSmallIcon {
  display: none;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    display: block;
  }
}

.logoText {
  margin-top: 4px;
  height: 15px;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: nowrap;
  color: #000;
  transition: opacity ease-in-out 0.15s, height ease-in-out 0.15s, margin-top ease-in-out 0.15s;
  overflow: hidden;

  .component__fly & {
    margin-top: 0;
    height: 0;
    opacity: 0;
  }

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    margin-left: 11px;

    &,
    .component__fly & {
      margin-right: 20px;
      width: 115px;
      height: auto;
      white-space: normal;
      opacity: 1;
    }
  }
}

/* Search container */
.searchContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 66%;
  transition-timing-function: linear;
  transition-duration: 0.33s;
  transition-property: width, margin;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    width: 70%;
  }
}

/* Menu button */
.menuButton {
  position: absolute;
  left: -16px;
  padding-right: 16px;
  padding-left: 16px;
  display: none;
  height: 40px;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    display: block;
  }
}

/* Cart */
.component .cart {
  @media screen and (max-width: $big-tablet-window-width - 1px) {
    margin-right: -16px;
    margin-left: 8px;
    justify-content: center;
    width: 52px;
    height: 40px;
  }
}
