@import '@styles/variables';

.component {
  padding-top: 28px;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  width: 100%;
  background-color: rgba(196, 196, 196, 0.17);
}

.content {
  padding-bottom: 70px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: $big-tablet-window-width - 1px) {
    padding-bottom: 55px;
    flex-direction: column;
  }

  @media screen and (max-width: $big-mobile-window-width - 1px) {
    padding-bottom: 40px;
  }
}

.cardsBlock {
  @media screen and (max-width: $big-tablet-window-width - 1px) {
    padding-top: 50px;
  }

  @media screen and (max-width: $big-mobile-window-width - 1px) {
    padding-top: 30px;
  }
}
